// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-showcase-1-js": () => import("./../../../src/pages/showcase/1.js" /* webpackChunkName: "component---src-pages-showcase-1-js" */),
  "component---src-pages-showcase-2-js": () => import("./../../../src/pages/showcase/2.js" /* webpackChunkName: "component---src-pages-showcase-2-js" */),
  "component---src-pages-showcase-3-js": () => import("./../../../src/pages/showcase/3.js" /* webpackChunkName: "component---src-pages-showcase-3-js" */),
  "component---src-pages-showcase-4-js": () => import("./../../../src/pages/showcase/4.js" /* webpackChunkName: "component---src-pages-showcase-4-js" */),
  "component---src-pages-showcase-5-js": () => import("./../../../src/pages/showcase/5.js" /* webpackChunkName: "component---src-pages-showcase-5-js" */)
}

